<template>
  <div class="card group cursor-pointer relative">
    <div class="text">
      <h5>{{ text }}</h5>
      <p v-if="link">-></p>
    </div>
    <div v-if="film" class="film"></div>
    <div
      class="h-full w-full"
      :class="{ 'transform group-hover:scale-110 transition-all': zoom }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "CardComponent",
  props: {
    text: {
      type: String,
      required: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    film: {
      type: Boolean,
      default: true,
    },

  },
});
</script>

<style lang="scss" scoped>
.text {
  @apply text-center font-header font-semibold;
  @apply transition-all opacity-0 z-30;
  @apply absolute top-1/2 left-1/2 -translate-x-1/2;
  @apply group-hover:top-[45%] group-hover:opacity-100;
}

.film {
  @apply w-full h-full hidden absolute top-0 z-10;
  @apply bg-cyan-500 bg-opacity-60 group-hover:block;
}
</style>