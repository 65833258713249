<template>
  <div class="min-h-screen responsive-wrapper py-10 space-y-6">
    <div class="grid md:grid-cols-8 gap-5">
      <div class="md:col-span-5 grid gap-5">
        <div class="grid md:grid-cols-4 gap-5">
          <div class="card flex items-center justify-center py-10">
            <div class="w-14">
              <img src="@/assets/black.png" alt="" />
            </div>
          </div>
          <div
            class="
              card
              col-span-full
              md:col-span-3
              border border-zinc-600
              !bg-zinc-900
              flex
              items-center
              justify-center
              p-6
              md:p-10
            "
          >
            <p>
              Tekena Williams is a Film Director specialised in the
              Cinematography of products, food and other subjects. Tekena is
              currently the Director at Red Dish Studios, a Studio focused on
              creating high quality content tailored to meeting brand
              specifications for and not limited to the fields of Hospitality.
            </p>
          </div>
        </div>
        <div
          class="
            card
            flex
            items-center
            justify-center
            py-8
            space-x-14
            md:space-x-20
          "
        >
          <Icon
            icon="bi:instagram"
            link="https://instagram.com/tekenawilliams"
          />
          <Icon icon="bi:twitter" link="https://twitter.com/tekenawilliams" />
          <Icon
            icon="bi:behance"
            link="https://www.behance.net/tekenawilliams"
          />
          <Icon
            icon="bi:snapchat"
            link="https://www.snapchat.com/add/williams_cash"
          />
        </div>
      </div>
      <div class="md:col-span-3 h-full">
        <Card :zoom="true" class="h-full">
          <img
            src="@/assets/tk-face.jpg"
            class="card"
            pre-load="eager"
            draggable="false"
          />
        </Card>
      </div>
    </div>
    <Card :zoom="true" :film="false" class="h-52">
      <img src="@/assets/equipment.jpg" pre-load="eager" draggable="false" />
    </Card>
    <div class="grid md:grid-cols-3 gap-5" v-if="videos.length > 1">
      <Card
        v-for="video in videos"
        :key="video.id.videoId"
        :text="video.title"
        :link="true"
        :zoom="true"
        class="h-96"
        @click="goToYoutube(video.link)"
      >
        <img :src="video.image" v-if="video.image" />
      </Card>
      <!-- <YouTube
        v-for="video in videos"
        :key="video"
        :src="video.id"
        width="100%"
      /> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import YouTubeVideo from "@/models/youtubeVideo";
import Card from "@/components/CardComponent.vue";
import Icon from "@/components/IconComponent.vue";
// import YouTube from "vue3-youtube";

export default defineComponent({
  name: "HomePage",
  components: { Card, Icon },
  setup() {
    const apiKey = process.env.VUE_APP_API_KEY;
    const playlistID = process.env.VUE_APP_PLAYLIST_ID;
    const playlistURL = `https://www.googleapis.com/youtube/v3/playlistItems?key=${apiKey}&playlistId=${playlistID}&part=snippet,contentDetails&order=date&maxResults=50`;
    const videos = ref([]);

    onMounted(async () => {
      const response = await axios.get(playlistURL);
      videos.value = response.data.items.map(
        (video) => new YouTubeVideo(video.snippet)
      );
    });

    const goToYoutube = (link) => {
      window.open(link, "_blank");
    };

    return { videos, goToYoutube };
  },
});
</script>
