<template>
  <a :href="link" target="_blank">
    <Icon class="hover:text-cyan-500" :icon="icon" width="28" />
  </a>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import { Icon } from "@iconify/vue";

export default defineComponent({
  name: "IconComponent",
  components: { Icon },
  props: {
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
</style>