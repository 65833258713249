import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './styles/main.scss'

// const googleConfig = {
//   apiKey: 'AIzaSyBK_SCgx68XxTmga6FNX7Ajlx3lOqYQhJk',
//   clientId: '1077854475050-golugoih8cg3pd50v1lna08dogio4cep.apps.googleusercontent.com'
// }

createApp(App).use(router).mount('#app')
