export default class YouTubeVideo {
  constructor(video) {
    this.id = video.resourceId.videoId;
    this.title = video.title;
    this.description = video.description;
    this.image = video.thumbnails.high ? video.thumbnails.high.url : null;
    this.channelId = video.channelId;
    this.channelTitle = video.channelTitle;
    this.position = video.position;
    this.link = `https://www.youtube.com/watch?v=${this.id}`
  }
}