<template>
  <router-view v-slot="{ Component }">
    <div class="w-screen text-white bg-zinc-900">
      <component :is="Component"></component>
    </div>
  </router-view>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLayout",
});
</script>