<template>
  <component :is="this.$route.meta.layout || layout">
    <slot />
  </component>
</template>

<script>
import { shallowRef } from "@vue/reactivity";
import { defineComponent } from "@vue/runtime-core";
import BaseLayout from "./layouts/baseLayout.vue";

export default defineComponent({
  name: "App",
  data: () => ({
    layout: shallowRef(BaseLayout),
  }),
});
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
